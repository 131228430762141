import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`9th February 2023`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
          }}>{`type://CircuitBreakerClient`}</a>{` integration with Resilience4j's circuit breaker is now available. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1469"
          }}>{`#1469`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4139"
          }}>{`#4139`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Note that `}<inlineCode parentName="li">{`armeria-resilience4j2`}</inlineCode>{` requires Java 17 or higher.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Resilience4jCircuitBreakerMapping mapping =
  Resilience4jCircuitBreakerMapping.of();
WebClient client = WebClient
  .builder()
  .decorator(CircuitBreakerClient
    .builder(CircuitBreakerRule.onException())
    .handler(Resilience4JCircuitBreakerClientHandler.of(mapping)) // 👈👈👈
    .newDecorator())
  ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://AsyncServerInterceptor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/AsyncServerInterceptor.html"
          }}>{`type://AsyncServerInterceptor`}</a>{` to asynchronously intercept gRPC service calls. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4647"
          }}>{`#4647`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class AuthServerInterceptor implements AsyncServerInterceptor {
  @Override
  <I, O> CompletableFuture<Listener<I>> asyncInterceptCall(
        ServerCall<I, O> call, Metadata headers, ServerCallHandler<I, O> next) {
    return authorizer.authorize(headers).thenApply(result -> {
      if (result) {
        return next.startCall(call, headers);
      } else {
        throw new AuthenticationException("Invalid access");
      }
    });
  }
}

GrpcService.builder()
           .intercept(new AuthServerInterceptor()) // 👈👈👈
           .addService(...)
           ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
          }}>{`type://@Blocking`}</a>{` annotation can now be used with gRPC services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4389"
          }}>{`#4389`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4585"
          }}>{`#4585`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Blocking // 👈👈👈 applies to all methods in the class
class MyGrpcServiceImpl extends MyGrpcServiceImplBase {
  @Blocking // 👈👈👈 applies to a single method
  public void blockingCall(...) {
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria server with Spring integration can now be configured to bind to non-ip addresses. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4597"
          }}>{`#4597`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4598"
          }}>{`#4598`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The authenticated remote user set by `}<a parentName="p" {...{
            "href": "type://RequestLogBuilder#authenticatedUser(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLogBuilder.html#authenticatedUser(java.lang.String)"
          }}>{`type://RequestLogBuilder#authenticatedUser(String)`}</a>{` is now logged in access
logs. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4634"
          }}>{`#4634`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` now exposes `}<a parentName="p" {...{
            "href": "https://json-schema.org/"
          }}>{`JSON Schema`}</a>{` via the `}<inlineCode parentName="p">{`/schemas.json`}</inlineCode>{` endpoint. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4518"
          }}>{`#4518`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Server#reconfigure(ServerConfigurator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html#reconfigure(com.linecorp.armeria.server.ServerConfigurator)"
        }}>{`type://Server#reconfigure(ServerConfigurator)`}</a>{` now dynamically updates TLS configurations correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4637"
        }}>{`#4637`}</a></li>
      <li parentName="ul">{`Thrift `}<a parentName="li" {...{
          "href": "http://diwakergupta.github.io/thrift-missing-guide/#_typedefs"
        }}>{`typedefs`}</a>{` are
now correctly displayed in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4628"
        }}>{`#4628`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` front-end assets are now compressed using gzip instead of Brotli. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4616"
        }}>{`#4616`}</a></li>
      <li parentName="ul">{`An exception is raised if TLS is set for a port-based virtual host. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4588"
        }}>{`#4588`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` is not raised anymore when an aborted `}<a parentName="li" {...{
          "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
        }}>{`type://StreamMessage`}</a>{` is collected. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4652"
        }}>{`#4652`}</a></li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "type://ClosedSessionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ClosedSessionException.html"
        }}>{`type://ClosedSessionException`}</a>{` is not set to `}<a parentName="li" {...{
          "href": "type://RequestLog#responseCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html#responseCause()"
        }}>{`type://RequestLog#responseCause()`}</a>{` anymore when
an HTTP/1 connection is closed after response data has been fully sent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4632"
        }}>{`#4632`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/endpoints/docs/grpc/transcoding"
        }}>{`gRPC HTTP/JSON transcoding`}</a>{` now doesn't throw an exception when used with nested messages. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4631"
        }}>{`#4631`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4641"
        }}>{`#4641`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`431 Request Header Fields Too Large`}</inlineCode>{` is now returned instead of `}<inlineCode parentName="li">{`400 Bad Request`}</inlineCode>{` if the header size exceeds `}<a parentName="li" {...{
          "href": "type://ServerConfig#http1MaxHeaderSize():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerConfig.html#http1MaxHeaderSize()"
        }}>{`type://ServerConfig#http1MaxHeaderSize()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4609"
        }}>{`#4609`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4655"
        }}>{`#4655`}</a></li>
      <li parentName="ul">{`Warnings on duplicate parameters in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` are no longer visible when annotations are added to Kotlin data classes. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4645"
        }}>{`#4645`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/endpoints/docs/grpc/transcoding"
        }}>{`gRPC HTTP/JSON transcoding`}</a>{` now correctly interprets `}<inlineCode parentName="li">{`google.protobuf.FieldMask`}</inlineCode>{` type as a string. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4633"
        }}>{`#4633`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4640"
        }}>{`#4640`}</a></li>
      <li parentName="ul">{`gRPC metadata is now exposed via `}<a parentName="li" {...{
          "href": "type://StatusRuntimeException"
        }}>{`type://StatusRuntimeException`}</a>{` in `}<a parentName="li" {...{
          "href": "type://RequestLog#responseCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html#responseCause()"
        }}>{`type://RequestLog#responseCause()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4635"
        }}>{`#4635`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4636"
        }}>{`#4636`}</a></li>
      <li parentName="ul">{`Square brackets "`}<inlineCode parentName="li">{`[]`}</inlineCode>{`" that are specified in path segments are correctly encoded. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4607"
        }}>{`#4607`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4615"
        }}>{`#4615`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DecodingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/DecodingService.html"
        }}>{`type://DecodingService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
        }}>{`type://DecodingClient`}</a>{` now correctly limit the max length of decompressed streaming data. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4613"
        }}>{`#4613`}</a></li>
      <li parentName="ul">{`Client-side gRPC callback `}<inlineCode parentName="li">{`ClientCall.Listener.onHeaders()`}</inlineCode>{` is now invoked correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4583"
        }}>{`#4583`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4608"
        }}>{`#4608`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` now correctly displays multiple example requests for a single endpoint. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4576"
        }}>{`#4576`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4605"
        }}>{`#4605`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ArmeriaSettings.ports.ip`}</inlineCode>{` is deprecated in favor of `}<inlineCode parentName="li">{`ArmeriaSettings.ports.address`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4597"
        }}>{`#4597`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4598"
        }}>{`#4598`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria's gRPC services now try to throw a `}<inlineCode parentName="li">{`StatusRuntimeException`}</inlineCode>{` instead of a `}<inlineCode parentName="li">{`StatusException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4658"
        }}>{`#4658`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Note that users are still recommended to perform error handling for both `}<inlineCode parentName="li">{`StatusRuntimeException`}</inlineCode>{` and `}<inlineCode parentName="li">{`StatusException`}</inlineCode>{`
for gRPC services.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`We are planning on stopping support for integration with Spring Boot 1. Please join the discussion at our
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4651"
        }}>{`Github issue`}</a>{` if you have any opinions. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4651"
        }}>{`#4651`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.14.1 → 5.15.0`}</li>
      <li parentName="ul">{`Dropwizard-metrics 4.2.13 → 4.2.15`}</li>
      <li parentName="ul">{`GraphQL Kotlin 6.3.2 → 6.3.5`}</li>
      <li parentName="ul">{`gRPC Java 1.49.1 → 1.52.1`}</li>
      <li parentName="ul">{`Hibernate Validator 6.2.3.Final → 6.2.5.Final`}</li>
      <li parentName="ul">{`Jackson 2.14.1 → 2.14.2`}</li>
      <li parentName="ul">{`java-jwt 4.2.1 → 4.2.2`}</li>
      <li parentName="ul">{`Kafka client 3.3.1 → 3.4.0`}</li>
      <li parentName="ul">{`Kotlin 1.7.22 → 1.8.10`}</li>
      <li parentName="ul">{`Micrometer 1.10.2 → 1.10.3`}</li>
      <li parentName="ul">{`Netty 4.1.86.Final → 4.1.87.Final`}</li>
      <li parentName="ul">{`io_uring 0.0.16.Final → 0.0.17.Final`}</li>
      <li parentName="ul">{`Protobuf 3.21.1 → 3.21.7`}</li>
      <li parentName="ul">{`Resteasy 5.0.4.Final → 5.0.5.Final`}</li>
      <li parentName="ul">{`RxJava3 3.1.5 → 3.1.6`}</li>
      <li parentName="ul">{`Sangria 3.4.1 → 3.5.0`}</li>
      <li parentName="ul">{`Scala3 3.2.0 → 3.2.2`}</li>
      <li parentName="ul">{`ScalaPB 0.11.12 → 0.11.13`}</li>
      <li parentName="ul">{`ScalaPB JSON 0.12.0 → 0.12.1`}</li>
      <li parentName="ul">{`Spring 5.3.23 → 5.3.25`}</li>
      <li parentName="ul">{`Spring Boot 2.7.4 → 2.7.8`}</li>
      <li parentName="ul">{`Tomcat8 8.5.84 → 8.5.85`}</li>
      <li parentName="ul">{`Tomcat9 9.0.70 → 9.0.71`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['cormoran', 'daniel-itunu', 'Dogacel', 'heesuk-ahn', 'hyperxpro', 'ikhoon', 'jrhee17', 'kezhenxu94', 'kojilin', 'minwoox', 'mscheong01', 'rhodo', 'sullis', 'ta7uw', 'tomatophobia', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      